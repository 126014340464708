const ManagerRoutes = [
  {
    path: '/manager',
    component: () => import('@/views/manager/Main'),
    meta: { check: 'manager' },
    children: [
      {
        path: '',
        component: () => import('@/views/manager/Dashboard'),
        meta: { check: 'manager' }
      },
      {
        path: '/manager/companys',
        component: () => import('@/views/manager/Companys'),
        meta: { check: 'manager' }
      }, 
      {
        path: '/manager/company/:id',
        component: () => import('@/views/manager/Company'),
        meta: { check: 'manager' }
      },
      {
        path: '/manager/products',
        component: () => import('@/views/manager/Products'),
        meta: { check: 'manager', provider: 'solution' }
      },
      {
        path: '/manager/products/:companyName',
        component: () => import('@/views/manager/Products'),
        meta: { check: 'manager', provider: 'solution' }
      }, 
      {
        path: '/manager/products/id/:id',
        component: () => import('@/views/manager/Products'),
        meta: { check: 'manager', provider: 'solution' }
      },
      {
        path: '/manager/finances/:type',
        component: () => import('@/views/manager/Finances'),
        meta: { check: 'manager', provider: 'solution' }
      },
      {
        path: '/manager/finances/:type/:id',
        component: () => import('@/views/manager/Finances'),
        meta: { check: 'manager', provider: 'solution' }
      },
      {
        path: '/manager/invoices/membership/:id?',
        component: () => import('@/views/manager/Invoices'),
        meta: { check: 'manager' }
      },
      {
        path: '/manager/invoices/barcode/:id?',
        component: () => import('@/views/manager/Invoices'),
        meta: { check: 'manager', provider: 'solution' }
      },
      {
        path: '/manager/files',
        component: () => import('@/views/manager/Files'),
        meta: { check: 'manager' }
      },
      {
        path: '/manager/administrators',
        component: () => import('@/views/manager/Administrators'),
        meta: { check: 'manager' },
        name: 'administrators'
      },
      {
        path: '/manager/mass/:type',
        component: () => import('@/views/manager/Notifications'),
        meta: { check: 'manager' },
        name: 'notifications'
      },
      {
        path: '/manager/users',
        component: () => import('@/views/manager/Users'),
        meta: { check: 'manager' },
        name: 'users'
      },
      {
        path: '/manager/site',
        component: () => import('@/views/manager/site/Main'),
        meta: { check: 'manager' },
        name: 'siteAdministrator',
        children: [
          {
            path: 'banners',
            component: () => import('@/views/manager/site/Banners'),
          },
          {
            path: 'menu/:position',
            component: () => import('@/views/manager/site/Menu'),
          },
          {
            path: 'pages/:page',
            component: () => import('@/views/manager/site/Pages'),
          },
          {
            path: 'pagelist/:page',
            component: () => import('@/views/manager/site/PageEditor'),
          },
          {
            path: 'pagelist',
            component: () => import('@/views/manager/site/Pagelist'),
          },
          {
            path: 'news',
            component: () => import('@/views/manager/site/News'),
          },
          {
            path: 'files',
            component: () => import('@/views/manager/site/Files'),
          },
          {
            path: 'settings',
            component: () => import('@/views/manager/site/Settings'),
          },
        ]
      }
    ]
  },
]

export default ManagerRoutes