import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import ka from './ka.json'

Vue.use(VueI18n)

const defaultLocale = localStorage.locale ? localStorage.locale : 'ka'
const fallbackLocale = 'en'
const languages = {
  en: en,
  ka: ka,
}

const messages = Object.assign(languages)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: fallbackLocale,
  messages
})
export default i18n

export const languagesArray = ["ka", "en"];

export function changeLanguage(lang) {
  i18n.locale = languagesArray.includes(lang) ? lang : defaultLocale
  localStorage.locale = i18n.locale
}