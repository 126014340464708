const SharedRoutes = [
    {
        path: "",
        alias: "/login/:token?",
        component: () => import('@/views/shared/Login'),
        meta: { check: 'loggedOut' }
    },
    {
        path: "*",
        component: () => import('@/views/shared/PageNotFound'),
    }
]

export default SharedRoutes