import vm from '@/main.js'
import axios from 'axios'
import validate from 'validate.js'
import Auth from '@/helpers/authentication'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: process.env.VUE_APP_API_TIMEOUT,
})

instance.interceptors.request.use(config => {
    let userData = localStorage.user ? JSON.parse(localStorage.user) : false
    if (userData && userData.token) {
        config.headers['Auth-Token'] = userData.token;
        return config;
    }

    const tokenPattern = /\/login\/(\S+)/;
    const token = window.location.pathname.match(tokenPattern);
    if (token && token[1]) {
        config.headers['Auth-Token'] = token[1];
    }

    return config;
})

instance.interceptors.response.use(function (response) {

    if (validate.isObject(response.data)) {
        return response;
    }

    vm.$toast.error(
        vm.$vuetify.lang.t("$vuetify.errors.system_error")
    );

    return Promise.reject();
}, function (error) {

    if (error.response.status == 404) {
        Auth.clearStorage()
        return vm.$router.push({
            path: '/login'
        })
    }
    if (error.response.status != 400 && error.response.status != 404) {
        vm.$toast.error(
            vm.$vuetify.lang.t("$vuetify.errors.system_error")
        );
    }
    return Promise.reject(error);
});

class ApiService {

    auth(act, data) {
        switch (act) {
            case 'login':
            case 'refresh':
            case 'email/resend':
                return instance.post('/user/' + act, data)
            case 'logout':
                return instance.get('/user/' + act)
        }
    }

    dashboard() {
        return instance.get('/dashboard')
    }

    table(request) {
        return instance.get(request)
    }

    company(act, data) {
        switch (act) {
            case 'status/update':
            case 'update':
            case 'create':
                return instance.post('/company/' + act, data)
            case 'get':
            case 'list':
            case 'delete':
                return instance.get('/company/' + act + '/' + data)
            case 'names':
            case 'gepir/upload':
            case 'gepir/validate':
                return instance.get('/company/' + act)
        }
    }

    contact(act, data) {
        switch (act) {
            case 'create':
            case 'update':
                if (data.company_id.id) {
                    data.company_id = data.company_id.id;
                }

                return instance.post('/contact/' + act, data)
            case 'delete':
                return instance.get('/contact/' + act + '/' + data)
        }
    }

    message(act, data, query) {
        switch (act) {
            case 'spam':
                return instance.post('/message/' + act + query, data)
            case 'seen':
                return instance.get('/message/' + act + '/' + data)
            default:
                return instance.post('/message/' + act, data)
        }
    }

    manager(act, data) {
        switch (act) {
            case 'create':
            case 'update':
                return instance.post('admin/user/' + act, data)
        }
    }

    invoice(act, data) {
        switch (act) {
            case 'create':
            case 'update':
                return instance.post('invoice/' + act, data)
        }
    }

    payment(act, data) {
        switch (act) {
            case 'update':
            case 'create':
                return instance.post('/payment/' + act, data)
        }
    }

    product(act, data) {
        switch (act) {
            case 'gepir/upload':
            case 'gepir/validate':
                return instance.get('/product/' + act)
            case 'get':
            case 'delete':
                return instance.get('/product/' + act + '/' + data)
            case 'update':
            case 'create':
                return instance.post('/product/' + act, data)
            case 'import':
                return instance.post('/product/' + act + '/' + data.get('company_id'), data)
        }
    }

    download(act, data) {
        switch (act) {
            case 'companyList':
                return instance.get(data, {
                    'responseType': 'blob',
                    'timeout': 360000
                });
            case 'invoice':
                return instance.get('/invoice/' + data, {
                    'responseType': 'blob',
                    'timeout': 360000
                })
            case 'gephir':
                return instance.get(data + '/gepir/export', {
                    'responseType': 'blob',
                    'timeout': 360000
                });
        }
    }

    gtin(act, data) {
        switch (act) {
            case 'segment':
                return instance.get('/gtin/' + act)
            case 'family':
            case 'class':
            case 'brick':
            case 'type':
            case 'value':
                return instance.get('/gtin/' + act + '?' + data)
        }
    }

    barcode(act, data) {
        switch (act) {
            case 'download':
                return instance.get('/product/barcodes/export?' + data, {
                    timeout: 0
                })
        }
    }

    ai(act, data) {
        switch (act) {
            case 'generate':
                return instance.post('/barcode/download/', data)
        }
    }

    file(act, data) {
        switch (act) {
            case 'remove':
                return instance.get('/company/media/delete/' + data)
            case 'upload':
                return instance.post('/company/' + act + '/' + data.get('company_id'), data)
        }
    }

    media(act, data) {
        switch (act) {
            case 'upload':
                return instance.post('/media/' + act, data)
        }
    }

    menu(action, menuPosition, data) {
        switch (action) {
            case 'get':
                return instance.get(action + '/menu/' + menuPosition);
            case 'set':
                return instance.post(action + '/menu/' + menuPosition, data);
        }
    }

    page(action, page, data) {
        var repl = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>';
        switch (action) {
            case 'all':
                return instance.get(action + '/page');
            case 'get':
                return instance.get(action + '/page/' + page);
            case 'delete':
                return instance.post(action + '/page/' + page);
            case 'set':
                if (typeof data.content !== 'undefined') {
                    if (typeof data.content.ka !== 'undefined') {
                        data.content.ka = data.content.ka.replace(repl, '');
                        data.content.en = data.content.en.replace(repl, '');
                    }
                }

                if (typeof data.blocks !== 'undefined') {
                    for (var i = 0; i < data.blocks.length; i++) {
                        data.blocks[i].content.ka = data.blocks[i].content.ka.replace(repl, '');
                        data.blocks[i].content.en = data.blocks[i].content.en.replace(repl, '');
                    }
                }

                if (typeof data.members !== 'undefined') {
                    for (var im = 0; im < data.members.length; im++) {
                        data.members[im].content.ka = data.members[im].content.ka.replace(repl, '');
                        data.members[im].content.en = data.members[im].content.en.replace(repl, '');
                    }
                }

                if (typeof data.bottom !== 'undefined') {
                    data.bottom.ka = data.bottom.ka.replace(repl, '');
                    data.bottom.en = data.bottom.en.replace(repl, '');
                }

                if (typeof data.banner !== 'undefined') {
                    data.banner.content.ka = data.banner.content.ka.replace(repl, '');
                    data.banner.content.en = data.banner.content.en.replace(repl, '');
                }

                return instance.post(action + '/page/' + page, data);
        }
    }

    settings(action, data) {
        switch (action) {
            case 'get':
                return instance.get(action + '/settings/');
            case 'set':
                console.log(data);
                return instance.post(action + '/settings/', data);
        }
    }

    slider(action, data) {
        var repl = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>';

        switch (action) {
            case 'get':
                return instance.get(action + '/slider');
            case 'set':

                if (typeof data !== 'undefined') {
                    for (var i = 0; i < data.length; i++) {
                        data[i].content.ka = data[i].content.ka.replace(repl, '');
                        data[i].content.en = data[i].content.en.replace(repl, '');
                    }
                }

                return instance.post(action + '/slider/', data);
        }
    }

    article(action, data) {
        var repl = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>';

        switch (action) {
            case 'news/add':
                data.full_text.ka = data.full_text.ka.replace(repl, '');
                data.full_text.en = data.full_text.en.replace(repl, '');
                return instance.post('article/' + action, data);
            case 'save':
                data.full_text.ka = data.full_text.ka.replace(repl, '');
                data.full_text.en = data.full_text.en.replace(repl, '');
                return instance.post('article/' + action + '/' + data.id, data);
            case 'delete':
                return instance.post('/article/' + action + '/' + data);
            case 'get':
            case 'list':
                return instance.get('article/news/' + data);
        }
    }

    user(action, data) {
        switch (action) {
            case 'password/change':
                return instance.post('user/' + action, data);
        }
    }
}

const Api = new ApiService;

export default Api