const CustomerRoutes = [
    {
        path: '/customer',
        component: () => import('@/views/customer/Main'),
        meta: { check: 'customer' },
        children: [
            {
                path: '',
                component: () => import('@/views/customer/Company'),
                meta: { check: 'customer' }
            },
            {
              path: '/customer/products/:id?',
              component: () => import('@/views/customer/Products'),
              meta: { check: 'customer', provider: 'solution' }
            }, 
            {
              path: '/customer/company',
              component: () => import('@/views/customer/Company'),
              meta: { check: 'customer' }
            }, 
            {
              path: '/customer/finances/:type/:id',
              component: () => import('@/views/customer/Finances'),
              meta: { check: 'customer' },
            },
            {
              path: '/customer/invoices/:type/:id',
              component: () => import('@/views/customer/Invoices'),
              meta: { check: 'customer' },
            },
        ]
    }
]

export default CustomerRoutes