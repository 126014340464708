import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'
import i18n from './i18n/index.js'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'top', // default
  color: 'info', // default
  icon: 'info',
  iconColor: '', // default
  classes: [
    'body-2'
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '', // default
  closeIcon: 'mdi-close', // default
  closeColor: 'white', // default
  slot: [], //default
  shorts: {
    ok: {
      color: '#1e7e34',
      icon: 'mdi-checkbox-marked-circle'
    },
    error: {
      color: 'red',
      icon: 'mdi-close-circle'
    },
    info: {
      color: '#17a2b8',
      icon: 'mdi-information'
    }
  },
  property: '$toast' // default
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#008DBD',
        secondary: '#00B6DE',
        accent: '#F4F4F4',
        success: '#71B790',
        info: '#8DB9CA',
        error: '#F26334',
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdi',
  },
})