import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n/index.js'
import vuetify from './plugins/vuetify'
import router from './router/index'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vueHeadful from 'vue-headful'

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';


require('default-passive-events');

Vue.config.productionTip = false
Vue.component('vue-headful', vueHeadful)

import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

Vue.mixin({
  data: function () {
    return {
      aiGeneratorState: false,
      drawer: null
    }
  },
  methods: {
    latinOnly(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9"'-_. ]+$/.test(char)) return true;
      else e.preventDefault();
    },
    closeNav() {
      if (window.innerWidth < 1260) {
        this.drawer = false;
      }
    }
  },
})

const vm = new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

export default vm