import vm from '@/main.js'

class Exception {
    login(error) {

        if (!error.response || !error.response.data || !error.response.data.error) {
            return vm.$toast.error(vm.$vuetify.lang.t("$vuetify.errors.system_error"))
        }

        if (error.response.data.error === 'unknown_user') {
            return vm.$toast.error(vm.$vuetify.lang.t("$vuetify.errors.invalid_credentials"));
        }

        if (error.response.data.error === 'temporary_disabled') {
            return vm.$toast.error(vm.$vuetify.lang.t("$vuetify.errors.account_disabled"));
        }

        if (error.response.data.error === 'email_not_verified') {
            return error.response.data.error;
        }

        return vm.$toast.error(vm.$vuetify.lang.t("$vuetify.errors.system_error"))
    }

    logout(e) {
        return e
    }
}

const e = new Exception;
export default e;