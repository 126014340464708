import exception from '@/handlers/exception.js'
import Api from "@/services/api";

class Authentication {

    isAuthenticated() {
        return localStorage.user ? JSON.parse(localStorage.user) : false;
    }

    async logIn(jsonCredentials) {

        if (typeof (jsonCredentials['username']) == 'undefined' || jsonCredentials['username'].length < 3) {
            return false;
        }

        if (typeof (jsonCredentials['password']) == 'undefined' || jsonCredentials['password'].length < 3) {
            return false;
        }

        var res = false

        await Api.auth("login", jsonCredentials).then(({
            data
        }) => {

            var perm = false;
            var role = false;
            switch (data.role) {
                case 'manager':
                    role = perm = 'manager'
                    break;
                case 'finance':
                    perm = 'finance'
                    role = 'manager'
                    break;
                case 'customer':
                    role = perm = 'customer'
            }

            var user = {
                id: data.id,
                name: data.name,
                role: role,
                permission: perm,
                token: data.token,
            }

            if (data.role == 'customer') {
                user.company_id = data.companies[data.companies.length - 1];
                user.companies = data.companies;
                user.company_names = data.company_names;
            }

            localStorage.user = JSON.stringify(user)
            res = true
        }).catch(error => {
            res = exception.login(error);
            return exception.login(error)
        });

        return res
    }

    async loginWithToken(token) {
        await Api.auth("refresh", token).then(({
            data
        }) => {
            var perm = false;
            var role = false;
            switch (data.role) {
                case 'manager':
                    role = perm = 'manager'
                    break;
                case 'finance':
                    perm = 'finance'
                    role = 'manager'
                    break;
                case 'customer':
                    role = perm = 'customer'
            }

            var user = {
                id: data.id,
                name: data.name,
                role: role,
                permission: perm,
                token: data.token,
            }

            if (data.role == 'customer') {
                user.company_id = data.companies[data.companies.length - 1];
                user.companies = data.companies;
                user.company_names = data.company_names;
            }

            localStorage.user = JSON.stringify(user)

            location.reload();
        }).catch(error => {
            return exception.login(error)
        });
    }

    async logOut() {
        await Api.auth("logout").then(({
            data
        }) => {
            return exception.logout(data)
        }).catch(error => {
            return exception.logout(error)
        });

        return true
    }

    async sendVerification(jsonEmail) {
        await Api.auth("email/resend", jsonEmail).then(({
            data
        }) => {
            return exception.logout(data)
        }).catch(error => {
            return exception.logout(error)
        });

        return true
    }

    clearStorage() {
        localStorage.removeItem('user');
    }
}

let Auth = new Authentication();

export default Auth