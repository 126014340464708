<template>
  <v-app>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<style>
@import url("assets/css/global.css");
</style>

<script>
export default {
  name: "App"
};
</script>
