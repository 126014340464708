import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/helpers/authentication'
import ManagerRoutes from './manager'
import CustomerRoutes from './customer'
import SharedRoutes from './shared'

Vue.use(VueRouter)

const routes = [...CustomerRoutes, ...ManagerRoutes, ...SharedRoutes];

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  var userData = Auth.isAuthenticated()

  if ((to.meta.check == 'manager' || to.meta.check == 'customer') && userData.role != to.meta.check) {
    
    Auth.clearStorage()
    next('/login')
  }

  if (to.meta.check == 'loggedOut' && userData) {
    if (userData.role == 'manager' || userData.role == 'customer') {
      next('/' + userData.role)
    }
  }

  next()
})

export default router
